<template>
  <div class="w-full bg-gray-lightest px-4">
    <div class="text-primary container-md pt-32 pb-20">
      <div class="flex flex-col items-center md:flex-row md:items-start justify-between gap-8 w-full">
        <div v-for="(element, index) in elements" :key="`element-${index}`" class="max-w-56 flex flex-col items-center justify-between gap-4">
          <img
            :src="`/images/instaride/${rt(element.img)}.svg`"
            :alt="rt(element.alt)"
            loading="lazy"
            :width="100"
            :height="70"
          >
          <div class="text-6xl font-bold">
            {{ rt(element.num) }}
          </div>
          <div class="-mt-4 font-bold text-center">
            {{ rt(element.text) }}
          </div>
        </div>
      </div>
      <div class="mt-24 gap-14 flex flex-col justify-center">
        <div class="text-2xl text-center">
          {{ text.firstPart }}
          <span class="text-4xl font-bold">{{ kmModel }} {{ $t('components.instaride.km') }}</span>
          {{ text.secondPart }}
          <span class="text-4xl font-bold">{{ carbon }} {{ $t('components.instaride.carbon') }}</span>
          {{ text.thirdPart }}
          <span class="text-4xl font-bold">€{{ price }}</span>
        </div>
        <div class="calculations-slider">
          <Slider
            v-model="kmModel"
            :min="kmRange[0]"
            :max="kmRange[1]"
            :tooltips="false"
            size="lg"
            :aria-label="promptText"
          />
        </div>
        <div class="text-center">
          {{ promptText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Slider from '@vueform/slider' // eslint-disable-line import/default

defineProps({
  elements: { type: Object, required: true },
  text: { type: Object, required: true },
  promptText: { type: String, required: true },
  kmRange: { type: Array, required: true },
})
const { rt } = useI18n()
const kmModel = ref(50)

const carbon = computed(() => {
  const num = kmModel.value * 0.236
  return (Math.round(num * 100) / 100).toFixed(2)
})
const price = computed(() => {
  const value = (kmModel.value * 0.4)
  return (value < 1.0) ? value.toFixed(2) : Math.floor(value)
})
</script>
